import { LOGOUT_URL } from '../pages/Chat/url';

export const logout = async () => {
  try {
    // Redirect the user to the logout URL
    window.location.href = LOGOUT_URL;
  } catch (error) {
    console.error('Error during logout:', error);
    throw error;
  }
};
