import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertType, MatrixState } from 'data';
import { MatrixClient } from 'matrix-js-sdk'; // Replace with actual types if needed
import { RoomData } from '../../pages/Chat/types';

const initialState: MatrixState = {
  client: null,
  loading: true,
  showInvitations: false,
  showRooms: false,
  selectedRoom: undefined,
  publicRooms: [],
  showPersons: false,
  searchQuery: '',
  rooms: [],
  invitations: [],
  persons: [],
  error: null,
  alert: {
    open: false,
    severity: 'success',
    message: ''
  }
};

const matrixSlice = createSlice({
  name: 'matrix',
  initialState,
  reducers: {
    setClient(state, action: PayloadAction<MatrixClient>) {
      state.client = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setRooms(state, action: PayloadAction<RoomData[]>) {
      const newRooms = action.payload;

      // Create a Set to track unique room IDs
      const existingRoomIds = new Set(state.rooms.map((room) => room.roomId));

      // Filter new rooms to include only unique ones
      const uniqueNewRooms = newRooms.filter((room) => {
        if (!existingRoomIds.has(room.roomId)) {
          existingRoomIds.add(room.roomId); // Add roomId to the set
          return true; // Keep this room
        }
        return false; // Discard this room
      });

      // Update state with unique rooms
      state.rooms = [...state.rooms, ...uniqueNewRooms];
    },

    setPublicRooms(state, action: PayloadAction<RoomData[] | undefined>) {
      const newPublicRooms = action.payload || [];

      // Create a Set to track unique room IDs
      const existingPublicRoomIds = new Set(state?.publicRooms?.map((room) => room.roomId));

      // Filter new public rooms to include only unique ones
      const uniqueNewPublicRooms = newPublicRooms.filter((room) => {
        if (!existingPublicRoomIds.has(room.roomId)) {
          existingPublicRoomIds.add(room.roomId); // Add roomId to the set
          return true; // Keep this room
        }
        return false; // Discard this room
      });

      // Update state with unique public rooms
      state.publicRooms = [...state.publicRooms, ...uniqueNewPublicRooms];
    },
    setInvitations(state, action: PayloadAction<RoomData[]>) {
      const newInvitations = action.payload;

      // Create a Set to keep track of unique room IDs
      const existingRoomIds = new Set(state.invitations.map((invite) => invite.roomId));

      // Filter new invitations to only include unique ones
      const uniqueNewInvitations = newInvitations.filter((invite) => {
        if (!existingRoomIds.has(invite.roomId)) {
          existingRoomIds.add(invite.roomId); // Add the roomId to the set
          return true; // Keep this invitation
        }
        return false; // Discard this invitation
      });

      // Update state with the unique invitations
      state.invitations = [...state.invitations, ...uniqueNewInvitations];
    },
    setPersons(state, action: PayloadAction<RoomData[]>) {
      state.persons = action.payload;
    },
    setSelectedRoom(state, action: PayloadAction<RoomData | undefined>) {
      state.selectedRoom = action.payload;
    },
    setShowInvitations(state, action: PayloadAction<boolean>) {
      state.showInvitations = action.payload;
    },
    setShowRooms(state, action: PayloadAction<boolean>) {
      state.showRooms = action.payload;
    },
    setShowPersons(state, action: PayloadAction<boolean>) {
      state.showPersons = action.payload;
    },
    setSearchQuery(state, action: PayloadAction<string>) {
      state.searchQuery = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setAlert(state, action: PayloadAction<AlertType>) {
      state.alert = action.payload;
    }
  }
});

export const {
  setClient,
  setLoading,
  setPersons,
  setPublicRooms,
  setAlert,
  setRooms,
  setInvitations,
  setSelectedRoom,
  setShowInvitations,
  setShowRooms,
  setShowPersons,
  setSearchQuery,
  setError
} = matrixSlice.actions;

export default matrixSlice.reducer;
