const colors = {
  blue: '#3f73f5',
  blue1: '#002DE3',
  primary: '#007bff',
  secondary: '#6c757d',
  success: '#28a745',
  danger: '#dc3545',
  warning: '#ffc107',
  info: '#17a2b8',
  light: '#f8f9fa',
  dark: '#343a40',
  white: '#ffffff',
  black: '#000000',
  darkBlue: 'rgba(63, 115, 245, 1)',
  iconColor: '#233F87',
  chartColorMale: 'rgba(253, 156, 156, 1)',
  chartColorFemale: 'rgba(167, 191, 250, 1)',
  chartColorOther: 'rgba(252, 223, 138, 1)',
  iconGreen: '#47C651',
  iconRed: '#FC5353',
  iconBlue: '#658FF7',
  iconYellow: '#FAD154',
  countMale: '#1a3067',
  countFemale: '#8a1616',
  countOthers: '#886600',
  IconSearch: '#3F73F5',
  IconChevronDown: '#3F3F3F',
  IconEdit: '#7EA1F8',
  IconTrash: '#FD9C9C',
  IconSearchGrey: '#909090',
  bgChip: '#ECF1FE',
  colorChip: '#1A3067',
  colorIconTrash: '#FD9C9C',
  colorIconRed: '#E02424', 
  gray: ['#6B7280'],
  colorTitleBlue:'#1A3067',
  colorBodyGrey:'#595959',
};

export default colors;
