// src/i18n.ts

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Initialize i18next with initReactI18next
const resources = {
  en: { translation: require('./locale/en.json') },
  de: { translation: require('./locale/de.json') }
};

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    debug: false
  });

export default i18n;
