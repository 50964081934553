// authSelectors.ts
import { createSelector } from 'reselect';
import { AuthState, RootState } from 'data'; // Adjust the import path according to your project structure

// Selector to access the auth state from the root state
const authSelector = (state: RootState) => state.auth;

/**
 * Memoized selectors for the auth state
 */
export const selectIsAuthenticated = createSelector(
  [authSelector],
  (authState: AuthState) => authState.isAuthenticated
);
