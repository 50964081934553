// selectors.ts
import { RootState } from 'data/store';
import { ChatState } from 'data/types';
import { createSelector } from 'reselect';

// Base selector to get the chat state
const chatSelector = (state: RootState) => state.chat;

/**
 * Helper function to create memoized selectors for different fields
 * @param field - The specific field of the chat state to select
 * @returns A memoized selector for the specified field
 */
const createChatSelector = <T extends keyof ChatState>(field: T) =>
  createSelector([chatSelector], (chatState): ChatState[T] => chatState[field]);

// Memoized selectors for various fields in the chat state
export const selectMessages = createChatSelector('messages');
export const selectLastMessageId = createChatSelector('lastMessageId');
export const selectMessage = createChatSelector('message');
export const selectIsCalling = createChatSelector('isCalling');
export const selectIsCallConnected = createChatSelector('isCallConnected');
export const selectCallTime = createChatSelector('callTime');
export const selectIsMuted = createChatSelector('isMuted');
export const selectIncomingCall = createChatSelector('incomingCall');
export const selectTypingPerson = createChatSelector('typingPerson');

export const selectActiveCall = createChatSelector('activeCall');
export const selectLoadState = createChatSelector('loadState');
export const selectMessagesFetched = createChatSelector('messagesFetched');
export const selectIsLoadMoreProcessing = createChatSelector('isLoadMoreProcessing');
export const selectIsMessageLoading = createChatSelector('isMessageLoading');
export const selectIsLoadingEarlier = createChatSelector('isLoadingEarlier');
export const selectChatLoading = createChatSelector('chatLoading');
