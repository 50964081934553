import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatState } from 'data';
import { LoadState, Messages } from '../../pages/Chat/types';
import { MatrixCall } from 'matrix-js-sdk';
// Define the initial state
const initialState: ChatState = {
  messages: [],
  lastMessageId: null,
  message: '',
  isCalling: false,
  isCallConnected: false,
  callTime: '00:00',
  isMuted: false,
  incomingCall: null,
  activeCall: null,
  loadState: {},
  messagesFetched: false,
  isLoadMoreProcessing: false,
  isLoadingEarlier: false,
  chatLoading: false,
  isMessageLoading: true,
  typingPerson: ''
};

// Define the slice
const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessages(state, action: PayloadAction<Messages[]>) {
      state.messages = action.payload;
    },
    setLastMessageId(state, action: PayloadAction<string | null>) {
      state.lastMessageId = action.payload;
    },
    setMessage(state, action: PayloadAction<string>) {
      state.message = action.payload;
    },
    setIsCalling(state, action: PayloadAction<boolean>) {
      state.isCalling = action.payload;
    },
    setIsCallConnected(state, action: PayloadAction<boolean>) {
      state.isCallConnected = action.payload;
    },
    setCallTime(state, action: PayloadAction<string | number>) {
      state.callTime = action.payload;
    },
    setIsMuted(state, action: PayloadAction<boolean>) {
      state.isMuted = action.payload;
    },
    setIncomingCall(state, action: PayloadAction<MatrixCall | null>) {
      state.incomingCall = action.payload;
    },
    setActiveCall(state, action: PayloadAction<MatrixCall | null>) {
      state.activeCall = action.payload;
    },
    setLoadState(state, action: PayloadAction<LoadState>) {
      state.loadState = action.payload;
    },
    setMessagesFetched(state, action: PayloadAction<boolean>) {
      state.messagesFetched = action.payload;
    },
    setIsMessageLoading(state, action: PayloadAction<boolean>) {
      state.isMessageLoading = action.payload;
    },
    setIsLoadMoreProcessing(state, action: PayloadAction<boolean>) {
      state.isLoadMoreProcessing = action.payload;
    },
    setTypingPerson(state, action: PayloadAction<string>) {
      state.typingPerson = action.payload;
    },
    setIsLoadingEarlier(state, action: PayloadAction<boolean>) {
      state.isLoadingEarlier = action.payload;
    },
    setChatLoading(state, action: PayloadAction<boolean>) {
      state.chatLoading = action.payload;
    }
  }
});

// Export actions for use in components
export const {
  setMessages,
  setTypingPerson,
  setLastMessageId,
  setMessage,
  setIsCalling,
  setIsCallConnected,
  setCallTime,
  setIsMuted,
  setIncomingCall,
  setActiveCall,
  setLoadState,
  setMessagesFetched,
  setIsLoadMoreProcessing,
  setIsLoadingEarlier,
  setChatLoading,
  setIsMessageLoading
} = chatSlice.actions;

// Export the reducer for use in the store
export default chatSlice.reducer;
