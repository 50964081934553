import { configureStore } from '@reduxjs/toolkit';
import {
  dashboardReducer,
  doctorReducer,
  specialistQualificationsReducer,
  matrixReduer,
  authReducer,
  chatReduer
} from 'data';

import { combineReducers } from 'redux';

// Combine reducers
const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  doctor: doctorReducer,
  auth: authReducer,
  matrix: matrixReduer,
  chat: chatReduer,
  specialistQualifications: specialistQualificationsReducer
});

// Create the Redux store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
export default store;

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
