import { RootState } from 'data/store';
import { MatrixState } from 'data/types';
import { createSelector } from 'reselect';

// Base selector to get the matrix state from RootState
const matrixSelector = (state: RootState): MatrixState => state.matrix;

/**
 * Helper function to create memoized selectors for different fields
 * @param field - The specific field of the Matrix state to select
 * @returns A memoized selector for the specified field
 */
const createMatrixSelector = <T extends keyof MatrixState>(field: T) =>
  createSelector(
    [matrixSelector], // Ensure matrixSelector returns MatrixState
    (matrixState: MatrixState): MatrixState[T] => matrixState[field]
  );

// Memoized selectors for various fields in the MatrixState

export const selectMatrixClient = createMatrixSelector('client');
export const selectMatrixLoading = createMatrixSelector('loading');
export const selectMatrixShowInvitations = createMatrixSelector('showInvitations');
export const selectMatrixPublicRooms = createMatrixSelector('publicRooms');
export const selectMatrixShowRooms = createMatrixSelector('showRooms');
export const selectMatrixSelectedRoom = createMatrixSelector('selectedRoom');
export const selectMatrixShowPersons = createMatrixSelector('showPersons');
export const selectMatrixSearchQuery = createMatrixSelector('searchQuery');
export const selectMatrixRooms = createMatrixSelector('rooms');
export const selectMatrixInvitations = createMatrixSelector('invitations');
export const selectMatrixPersons = createMatrixSelector('persons');
export const selectMatrixError = createMatrixSelector('error');
export const selectMatrixAlert = createMatrixSelector('alert');
