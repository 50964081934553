import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from 'data/types'; // Ensure you have this interface defined

const initialState: AuthState = {
  isAuthenticated: false
};

// Create the authentication slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Action to set authentication status
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    }
  }
});

// Export actions
export const { setIsAuthenticated } = authSlice.actions;

// Export reducer
export default authSlice.reducer;
